@import '../../styles/customMediaQueries.css';

.WaveformContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: transparent;
}

.Wave {
  width: 100%;
  height: 90px;
  padding-top: 6%;
}

.PlayButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
}

.WaveTitle {
  position: absolute;
  /* right: 50%; */
  margin-top: -1%;

  @media (max-width: 550px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: -6%;
  }

  @media (min-width: 800px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: -4%;
  }

  @media (min-width: 800px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: -2%;
  }
}

/* from https://cssloaders.github.io/ */
.loader {
  position: relative;
  width: 100%;
  height: 50px;
  background-repeat: no-repeat;
  background-image: linear-gradient(var(--marketplaceColor) 50px, transparent 0),
    linear-gradient(var(--marketplaceColor) 50px, transparent 0),
    linear-gradient(var(--marketplaceColor) 50px, transparent 0),
    linear-gradient(var(--marketplaceColor) 50px, transparent 0),
    linear-gradient(var(--marketplaceColor) 50px, transparent 0),
    linear-gradient(var(--marketplaceColor) 50px, transparent 0);
  background-position: 0px center, 15px center, 30px center, 45px center, 60px center, 75px center, 90px center;
  animation: rikSpikeRoll 0.65s linear infinite alternate;
}

@keyframes rikSpikeRoll {
  0% {
    background-size: 10px 3px;
  }

  16% {
    background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px
  }

  33% {
    background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px
  }

  50% {
    background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px
  }

  66% {
    background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px
  }

  83% {
    background-size: 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px
  }

  100% {
    background-size: 10px 3px, 10px 3px, 10px 3px, 10px 10px, 10px 30px, 10px 50px
  }
}